//Bootstrap JS
import * as bootstrap from 'bootstrap';

//glightbox
import GLightbox from 'glightbox';

// swiper
import Swiper from 'swiper/swiper-bundle.min.js';

GLightbox({
    selector:'.glightbox'
});

var swiper = new Swiper(".swiper-slider", {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".carousel-control-next",
      prevEl: ".carousel-control-prev",
    },
  });

let lgMaxWidth = window.matchMedia("(max-width: 992px)");

lgMaxWidthHandler(lgMaxWidth);
lgMaxWidth.addEventListener("change", lgMaxWidthHandler);



function lgMaxWidthHandler(e) {
    if (e.matches) {
        dataMove();
    } else {
        dataMove(true);
    }
}

//swiper
var swiper = new Swiper(".carreview", {
    loop: true,
    spaceBetween: 30,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
        // mobile
        0: {
            spaceBetween: 10,
        },
        // small
        576: {
            spaceBetween: 10,
        },
        // medium
        768: {
            spaceBetween: 10,
        },
        // large
        992: {
            spaceBetween: 20,
        },
        // x-large
        1200: {
            spaceBetween: 25,
        }
    }
  });
  var swiper2 = new Swiper(".mySwiper2", {
    loop: true,
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },

    
  });
  
  //end swiper

function dataMove(moveBack = false) {
    const elements = document.querySelectorAll('[data-move]');

    elements.forEach(sourceElement => {
        const target = sourceElement.getAttribute('data-move');
        const targetElement = document.querySelector(target);

        if (targetElement)
        {
            if (moveBack) {
                moveChildElements(targetElement, sourceElement);
            } else {
                moveChildElements(sourceElement, targetElement);
            }
        }
    });
}

function moveChildElements(sourceElement, destinationElement) {
    while (sourceElement.firstChild) {
        destinationElement.appendChild(sourceElement.firstChild);
    }
}